import {
  Button,
  Hidden,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { orange, red } from '@material-ui/core/colors';

import { useMailchimp } from 'react-use-mailchimp';

const url = [
  'https://swingpulse.us12.list-manage.com',
  '/subscribe/post?u=e33e22565be91a69007bea9a0&amp;id=bdbdf49eb6',
].join('');

export const SubscriptionForm = ({ countOfSubscribers = null }) => {
  const [{ loading, error, data }, subscribe, reset] = useMailchimp({ url });
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  return (
    <>
      {countOfSubscribers ? (
        <Typography gutterBottom>
          More than <b>{Math.floor(countOfSubscribers / 10) * 10}</b> human
          beeings are in! Join us.
        </Typography>
      ) : null}
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'unset',
          padding: '20px 20px 0px 20px',
          border: `3px solid white`,
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (window.ga) {
              window.ga(
                'send',
                'event',
                'NEWSLETTER',
                'SUBMIT',
                'UNVERIFIED',
                1
              );
            }
            subscribe({ EMAIL: email, NAME: name });
          }}
        >
          <TextField
            fullWidth
            value={email}
            required
            margin={'normal'}
            label={'Enter your email'}
            disabled={loading}
            onChange={(e) => {
              setEmail(e.target.value);
              reset();
            }}
            onFocus={() => reset()}
            variant={'outlined'}
            error={!!error}
            helperText={
              !!loading
                ? 'Loading...'
                : error
                ? `Error during subscription ${
                    error.message ? error.message : ''
                  }`
                : data && data.result === 'success'
                ? 'Subscribed! Thank you!'
                : null
            }
          />

          <div style={{ marginTop: '12px', minHeight: '48px' }}>
            <Button
              type={'submit'}
              variant={'outlined'}
              style={{ float: 'right' }}
            >
              SUBMIT
            </Button>
          </div>
        </form>
      </Paper>
    </>
  );
};
